.fullframe{
    min-height: 720px;
    display: flex;
    flex-direction: row;
}

.ui.segment.masterframe {
    margin-top: 0px;
}

.menuframe{
    min-height: 720px;
    flex-grow: 0;
    width: 200px;
}

.contentframe{
    min-height: 720px;
    height: 100%;
    flex-grow: 1;
}

.storeselector{
    display: flex;
    flex-direction: row;
}

.storeselector .ui.menu {
    margin-top: 0px;
    margin-left: 10px;
}

.storeselector.topbar{
    padding: 20px;
}

.storeselector .ui.menu .item{
}

div.store.sales{
    display: grid;
    grid-template-rows: 50px auto;
    grid-template-columns: auto 100px;
    margin-bottom: 30px;
}

.store.sales .rightpanel{
    grid-row: 2;
    grid-column: 2;
    width: 250px;
}

.store.sales .rightpanel .paymentstatus{
    margin-top: 15px;
    margin-right: 20px;
}

.salesdata .weeklysaleslist {
}

.store.sales .leftpanel{
    grid-row: 1 / span 2;
    grid-column: 1;
    width: auto;
    padding: 0px 20px;
}

.store.sales h1{
   text-align: center;
   grid-row: 1;
   grid-column: 1 / span 2;
}

.saleslogform{
    flex-direction:column;
    display: flex;
}

.fmtnumber{
    text-align: right !important;
    font-family: 'Courier New';
}

div.formlabel{
    display: flex;
    justify-content: space-between;
}

div.formlabel span.labeltext{
    font-size: 0.92em;
    font-weight:700;
    text-transform: none;
    color: rgba(0,0,0,0.87);
    margin: 0 0 .29571429rem 0;
}

div.navmenu{
    width: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px;   
    border-right: 1px solid #eeeeee;
}

div.navmenu .navbutton{
    margin-bottom: 5px;
}